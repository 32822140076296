import React from 'react';

import Layout from '@components/layout';
import SEO from '@components/seo';
import Banner from '@components/banner';
import DiscoverProducts from '@components/discoverProducts';
import { CategorySubcontractors } from '@constants/app';

import '@styles/styles.scss';

const SubcontractorsPage = ({ data }) => {
  const ctf = data.contentfulDiscoverPages;
  const {
    forSubcontractorsBannerTitle,
    forSubcontractorsBannerSubtitle,
    forSubcontractorsBannerImageTitle,
    forSubcontractorsTitle,
    forSubcontractorsDescription
  } = ctf;
  const assets = data.allContentfulAsset.edges.map(edge => edge.node);

  const tradeUpDescription = ctf.tradeUpDescription.tradeUpDescription;
  const workUpDescription = ctf.workUpDescription.workUpDescription;
  const serviceUpDescription = ctf.serviceUpDescription.serviceUpDescription;
  const projectUpDescription = ctf.projectUpDescription.projectUpDescription;
  const checkUpDescription = ctf.checkUpDescription.checkUpDescription;
  const upFamilyDescription = ctf.upFamilyDescription.upFamilyDescription;

  return (
    <Layout>
      <SEO title="DiscoverSubcontractors"/>
      <Banner
        assets={assets}
        imageTitle={forSubcontractorsBannerImageTitle}
      >
        <div className="d-flex flex-column mt-3 mb-5 mb-md-0">
          <h1>{forSubcontractorsBannerTitle}</h1>
          <p className="text-muted">{forSubcontractorsBannerSubtitle.forSubcontractorsBannerSubtitle}</p>
        </div>
      </Banner>
      <DiscoverProducts
        productCategory={CategorySubcontractors}
        title={forSubcontractorsTitle}
        description={forSubcontractorsDescription.forSubcontractorsDescription}
        tradeUpDescription={tradeUpDescription}
        workUpDescription={workUpDescription}
        serviceUpDescription={serviceUpDescription}
        projectUpDescription={projectUpDescription}
        checkUpDescription={checkUpDescription}
        upFamilyDescription={upFamilyDescription}
      />
    </Layout>
  );
};

export default SubcontractorsPage;

export const pageQuery = graphql`
  query DiscoverSubcontractorsQuery {
    contentfulDiscoverPages {
      forSubcontractorsBannerTitle
      forSubcontractorsBannerSubtitle {
        forSubcontractorsBannerSubtitle
      }
      forSubcontractorsBannerImageTitle
      forSubcontractorsTitle
      forSubcontractorsDescription {
        forSubcontractorsDescription
      }
      tradeUpDescription {
        tradeUpDescription
      }
      workUpDescription {
        workUpDescription
      }
      serviceUpDescription {
        serviceUpDescription
      }
      projectUpDescription {
        projectUpDescription
      }
      checkUpDescription {
        checkUpDescription
      }
      upFamilyDescription {
        upFamilyDescription
      }
    }
    allContentfulAsset {
      edges {
        node {
          title
          file {
            contentType
            url
            fileName
          }
          fixed {
            height
            width
            src
            srcSet
          }
          fluid(maxWidth: 980) {
            ...GatsbyContentfulFluid
          } 
        }
      }
    }
  }
`;
